

jQuery.fn.rotate = (degrees) ->
  $(this).css({'transform' : 'translateX(-50%) rotate('+ degrees + 'deg)'});
  return $(this);


$(document).ready ->
  resizeAll = (firstTime) ->
    windowHeight = $(window).height();
    windowWidth = $(window).width();
    headerHeight = $('header').height();
    mobileWidth = 768
    
    mobile = if windowWidth >= mobileWidth then false else true
    desktop = !mobile

    $('.wrapper-fullsize').height(windowHeight)
    $('.wrapper-fullsize a img').width(windowWidth / 5)
    if mobile
      $('.wrapper-container h1').css('font-size', (windowWidth * 120 / mobileWidth))
      $('.wrapper-container h2 img').css('width', (windowWidth * 500 / mobileWidth))
    #wrapperHeight = windowHeight;

    #$('#wrapper').css('minHeight', wrapperHeight);

  $(window).on 'resize', resizeAll
  $(window).resize()

  $('body').on 'click', '.scroll-bottom img', ->
    $('html, body').animate( { scrollTop: $('.wrapper-invitation').offset().top }, 750);

  ###
  # Form
  # Focus / Blur placeholder are in utils.coffee
  ###
  $('form').on 'submit', (e) ->
    e.preventDefault();
    regexEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    test = true
    $form = $(this)
    $form.find('input,span,checkbox').removeClass 'error'

    $form.find('input[type=text].required, select.required').each ->
      if $(this).val() == $(this).data('placeholder') || $(this).val() == ''
        $(this).addClass 'error'
        test = false
      true

    ###
    if $form.find('input').val() == '' or $form.find('input').val() == $form.find('input').data('placeholder')
      $form.find('input').addClass 'error'
      test = false
    console.log test
    ###
    if !regexEmail.test($form.find('input[name=email]').val())
      $form.find('input[name=email]').addClass 'error'
      test = false

    #if !/\d\d\/\d\d\/\d\d\d\d/.test($form.find('input[name=birthday]').val())
    #  $form.find('input[name=birthday]').addClass 'error'
    #  test = false

    if test
      if window.ga?
        window.ga('send', 'event', 'Minisite', 'Formulaire enregistré');
        #if $form.find('#nl').is(':checked')
        #  window.ga('send', 'event', 'Minisite', 'Inscription NL');
      $.post $(this).attr('action'), {
        #firstname: $form.find('[name=firstname]').val()
        #lastname: $form.find('[name=lastname]').val()
        email: $form.find('[name=email]').val()
        phone: $form.find('[name=phone]').val()
        #sex: $form.find('[name=sex]').val()
      }, ->
        alert 'MERCI, CROISEZ LES DOIGTS'
        return
    false

